<template>
	<ion-page>
		<ion-progress-bar
			v-if="store.state.isLoading"
			type="indeterminate"
		></ion-progress-bar>

		<ion-toolbar color="polosan">
			<ion-buttons slot="start">
				<ion-back-button default-href="/login" text=""></ion-back-button>
			</ion-buttons>
		</ion-toolbar>

		<ion-content :fullscreen="true" class="ion-padding">
			<div>
				<ion-grid>
					<ion-row>
						<ion-col>
							<span class="small">Enter 6 digit code send to</span>
							<br /><span class="email">{{ email }}</span>
							<input
								type="number"
								required
								placeholder="000000"
								v-model.number="code.value"
								max="999999"
								min="1"
								maxlength="6"
								:disabled="code.disabled"
							/>
							<span v-if="code.error != null" class="error">{{
								code.error
							}}</span>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
			<div class="bottom">
				<ion-grid>
					<ion-row>
						<ion-col v-if="(code.target != null) & (code.target > 0)">
							<span class="small">Didn't receive it ?</span><br /><span
								>Request a new code in <b>{{ code.target }}</b> seconds</span
							>
						</ion-col>
						<ion-col v-else>
							<span class="small">Didn't receive it ?</span><br /><span
								><a class="ari" href="/login">Request a new code</a></span
							>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonPage,
	// IonLabel,
	// IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonProgressBar,
	// IonHeader,
	IonToolbar,
	IonButtons,
	IonBackButton,
} from "@ionic/vue";
import { inject, reactive, watchEffect } from "vue";
//import { useRouter } from "vue-router";
export default {
	name: "VerifyCode",
	components: {
		IonContent,
		IonPage,
		// IonLabel,
		// IonButton,
		IonGrid,
		IonRow,
		IonCol,
		IonProgressBar,
		// IonHeader,
		IonToolbar,
		IonButtons,
		IonBackButton,
	},
	setup() {
		const store = inject("store");
		//const router = useRouter();

		//set reactive value
		const code = reactive({
			value: null,
			target: null,
			error: null,
			disabled: false,
		});
		//count down target 3 minutes
		var future = new Date().getTime() + 180000;
		var x = setInterval(function() {
			var now = new Date().getTime();
			var distance = future - now;
			code.target = parseInt(distance / 1000);
			// If the count down is over, write some text
			if (distance < 0) {
				//clear interval to prevent force redirect on future
				clearInterval(x);
				code.target = null;
			}
		}, 1000);

		//displaying email
		const email =
			localStorage.getItem("DoctorEmail") != null
				? localStorage.getItem("DoctorEmail")
				: "";

		async function checkValidUser() {
			store.state.isLoading = true;
			code.disabled = true;
			code.error = null;

			try {
				const r = await store.userVerification.verifyCode(code.value);
				//console.log("r verify response", r);
				if (r.status === 200 && r.data.data.ResponseCode == "000") {
					//clear interval to prevent force redirect on future
					clearInterval(x);
					console.log("clearInterval");
					//set value on localStorage DOCTOR_ISONLINE
					localStorage.setItem("DoctorToken", r.data.data.DOCTOR_TOKEN);
					localStorage.setItem("DoctorName", r.data.data.DOCTOR_NAME);
					localStorage.setItem("DoctorCategory", r.data.data.DOCTOR_CATEGORY);
					localStorage.setItem("DoctorPhoneNo", r.data.data.DOCTOR_PHONE_NO);
					localStorage.setItem("DoctorPhotoUrl", r.data.data.DOCTOR_PHOTO_URL);
					localStorage.setItem("DoctorIsOnline", r.data.data.DOCTOR_ISONLINE);
					localStorage.setItem("DoctorYear", r.data.data.DOCTOR_YEAR);
					localStorage.setItem("DoctorId", r.data.data.DOCTOR_ID);
					localStorage.setItem("TokenAap", r.data.token);
					// localStorage.setItem(
					// 	"DoctorTokenExp",
					// 	new Date().getTime() + 1000 * 60 * 59 * 12
					// );

					//get list chat
					//store.ticket.listRequestConsultation();
					//store.ticket.listStartConsultation();

					setTimeout(() => {
						//set authentication
						store.state.auth = true;
						code.error = null;
						code.disabled = false;
						store.state.isLoading = false;
						//redirect to page conversatoin when success
						window.location.replace("/conversation");
						//router.replace("/conversation");
					}, 1000);
				}
				if (r.status === 200 && r.data.data.ResponseCode == "100") {
					setTimeout(() => {
						code.error = "Code verifikasi tidak valid";
						store.state.isLoading = false;
						code.disabled = false;
					}, 1000);
				}
			} catch (err) {
				console.log(`cath error: ${err}`);
				store.state.isLoading = false;
				code.error = "Gagal terhubung ke server, coba lagi nanti";
				code.disabled = false;
			}
		}

		//reading code.value when user type value on input field
		watchEffect(() => {
			if (code.value != null) {
				code.error = null;
				if (typeof code.value === "string") {
					const val = code.value;
					code.value = parseInt(val.substring(0, val.length - 1));
					code.error = "Please input number only!";
				}
			}
			if (code.value != null && code.value >= 100000 && code.value <= 999999) {
				checkValidUser();
			}
			if (code.value != null && code.value > 999999) {
				const val = code.value;
				code.value = parseInt(val.toString().substring(0, 6));
			}
		});

		return { store, email, code };
	},
};
</script>

<style scoped>
ion-content {
	--overflow: hidden;
}
ion-label {
	margin-left: 3px;
}

input {
	width: 100%;
	height: 43px;
	border: none;
	font-size: 30px;
	border-radius: 10px;
	padding: 0;
	margin: 8px 0;
	padding: 0 10x;
	outline: none;
	color: grey;
}
input:disabled {
	background: #ffffff;
	color: rgb(207, 207, 207);
}
.logo {
	width: 100px;
	margin: 0 auto;
}
.version {
	margin: 0 auto;
	margin-bottom: 40px;
	color: var(--ion-color-ari);
}
.email {
	color: #000000;
	font-weight: bold;
	margin-top: 5px;
}
.small {
	font-size: 12px;
}
.error {
	font-size: 12px;
	color: rgb(189, 43, 43);
}
.bottom {
	position: absolute;
	bottom: 0;
	margin-bottom: 20px;
}
.ari {
	text-decoration: none;
	color: #444444;
	font-weight: bold;
}
/* .center {
	margin: 0 auto;
	width: 100%;
	max-width: 375px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>
